import padilogo from '../../assets/images/padi-logo-transparent.png';
import { CSSTransition } from "react-transition-group";
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Email, WorkOutlined, Phone } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify'
const Register = () => {
    const [data, setData] = useState({ showPass1: false, showPass2: false });
    const [error, setError] = useState(null);
    const history = useHistory()


    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.password !== data.password2) {
            toast.error("Password doesn't match")
            return
        }
        fetch("/api/users/register", {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    let data = await res.json();
                    //  console.log(data);
                    setError(data?.errorMessage);
                    setTimeout(() => {
                        setError(null);
                    }, [5000])
                }
                else {
                    history.push('/register-success');
                }
            })
            .catch(err => {
                console.log('err', err.response.data)
            })
    }


    const togglePass1 = () => {
        setData({ ...data, showPass1: !data.showPass1 })
    }
    const togglePass2 = () => {
        setData({ ...data, showPass2: !data.showPass2 })
    }
    return (<CSSTransition timeout={1500} classNames='auth'>
        <div className='vertical-center authentification'>
            <div className='text-center' id='login'>
                <img src={padilogo} id='logo-login' height="150" text-align="center"></img>
                <div className='alert alert-danger' id='error' style={{ display: error === null ? 'none' : '' }}>{error}</div>
                <div className='block-content block block-400'>
                    <form onSubmit={handleSubmit} id='login-form'>
                        <fieldset>
                            <label><Person /></label>
                            <input type='text' placeholder='PACs Username' name='username' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Lock /></label>
                            <input type={data.showPass1 ? 'text' : 'password'} placeholder='Password' name='password' onChange={handleChange} required />
                            <button type='button' style={{ border: 'none', background: 'none' }} onClick={togglePass1}> {data.showPass1 ? <Visibility /> : <VisibilityOff />}</button>
                        </fieldset>

                        <fieldset>
                            <label><Lock /></label>
                            <input type={data.showPass2 ? 'text' : 'password'} placeholder='Retype Password' name='password2' onChange={handleChange} required />
                            <button type='button' style={{ border: 'none', background: 'none' }} onClick={togglePass2}> {data.showPass2 ? <Visibility /> : <VisibilityOff />}</button>
                        </fieldset>

                        <fieldset>
                            <label><Person /></label>
                            <input type='text' placeholder='First Name' name='firstname' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Person /></label>
                            <input type='text' placeholder='Last Name' name='lastname' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Phone /></label>
                            <input type='tel' placeholder='Phone Number' name='phone' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><Email /></label>
                            <input type='email' placeholder='Email' name='email' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><WorkOutlined /></label>
                            <input type='text' placeholder='Department' name='department' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><WorkOutlined /></label>
                            <input type='text' placeholder='Place Of Work' name='place' onChange={handleChange} required />
                        </fieldset>

                        <fieldset>
                            <label><WorkOutlined /></label>
                            <input type='text' placeholder='Practicing Number' name='practicing_no' onChange={handleChange} required />
                        </fieldset>

                        <div className='r-and-f'>


                            <div style={{ fontSize: '1rem' }}>
                                Already have account ?
                                <Link to={'/'}>
                                    <label style={{ fontStyle: 'italic', cursor: 'pointer' }}> Login</label>
                                </Link>
                            </div>
                        </div>

                        <button name='connexion' type='submit' className='login-btn' > Register </button>


                    </form>
                    <br />
                    <br />
                </div>
            </div>
        </div>

    </CSSTransition>)
}

export default Register;