import CommonTable from "../CommonComponents/RessourcesDisplay/ReactTable/CommonTable";
import React, { useMemo } from "react";
import {
  InputCell,
  SelectCell,
} from "../CommonComponents/RessourcesDisplay/ReactTable/EditableCells";
import { Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import ActionBoutonView from "../CommonComponents/RessourcesDisplay/ActionButtonViewAllCase";
import ActionButtonReport from "../CommonComponents/RessourcesDisplay/ActionButtonReport";
import ActionBouton from "../CommonComponents/RessourcesDisplay/ActionBouton";
function formateDateStr(str) {
  //20220728
  if (!str) return "";
  return `${str?.slice(6)}/${str?.slice(4, 6)}/${str?.slice(0, 4)}`;
}

export default function DoctorListTable({ reports, setDelete }) {
  const roles = useSelector((state) => state?.PadiMedical?.roles);
  const columns = useMemo(
    () => [
      {
        accessor: "No",
        Header: "No",
      },
      {
        accessor: "study_id",
        show: false,
      },
      {
        accessor: "addendumby",
        show: false,
      },
      {
        accessor: "StudyInstanceUID",
        show: false,
      },
      {
        accessor: "patient_name",
        Header: "Patient Name",
        sort: true,
      },
      {
        accessor: "patient_id",
        Header: "ID",
        sort: true,
      },
      {
        Header: "Accesion",
        accessor: "accesor",
      },
      {
        accessor: "study_type",
        Header: "Study Type",
        sort: true,
      },
      {
        accessor: "study_date",
        Header: "Study Date",
        Cell: ({ row }) => {
          return <p>{formateDateStr(row?.values?.study_date)}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sort: true,
        Cell: ({ row }) => {
          return (
            <p>
              {row.values.addendumby === undefined ? (
                <b className="text-danger">Not Finalize</b>
              ) : (
                <b className="text-success">Finalize</b>
              )}
            </p>
          );
        },
      },
      {
        accessor: "doctors",
        Header: "Doctor Incharge",
        sort: true,
        Cell: ({ row }) => {
          return <p>{row?.values?.doctors?.join(" , ")}</p>;
        },
      },
      {
        id: "select",
        Header: "Select",
        editable: false,
        Cell: ({ row }) => {
          return (
            <ActionBouton
            level="studies"
            orthancID={row.values.study_id}
            StudyInstanceUID={row.values.StudyInstanceUID}
            onDelete={()=>{}}
            row={row}
            refresh={()=>{}}
            pname={row.values.patient_name}
            pid={row.values.patient_id}
            StudyDescription={row.values.study_type}
            openLabelModal={()=>{}}
 
            />
          );
        },
      },
      {
        id: "view",
        Header: "View",
        editable: false,
        Cell: ({ row }) => {
          return (
            <ActionBoutonView
              //tukar link - osimis viewer
              StudyInstanceUID={row.values.StudyInstanceUID}
              wsi_link={
                "https://svrclotstudy.padimedical.com/wsi/app/index.html?series=" +
                row.values.study_id  //For rishab to adds on - add SeriesOrthancID
              }
              osimis_link={
                "https://svrclotstudy.padimedical.com/osimis-viewer/app/index.html?study=" +
                row.values.study_id
              }
              OhifLink={"/viewer-ohif/viewer/" + row.values.StudyInstanceUID}
              radiant={
                "radiant://?n=pstv&v=0020000D&v=%22" +
                row.values.StudyInstanceUID
              }
              osirix={
                "osirix://?methodName=downloadURL&URL=https://svrclotstudy.padimedical.com/studies/" +
                row.values.study_id +
                "/archive"
              }
              downloadzip={
                "https://svrclotstudy.padimedical.com/studies/" +
                row.values.study_id +
                "/archive"
              }
            />
          );
        },
      },
      {
        id: "report",
        Header: "Report",
        editable: false,
        Cell: ({ row }) => {
          return (
            <ActionButtonReport
              pid={row.values.patient_id}
              pname={row.values.patient_name}
              accessor={row.values.accessor}
              StudyInstanceUID={row.values.StudyInstanceUID}
              StudyOrthancID={row.values.study_id}
              description={{
                StudyDescription: row.values.study_type,
                StudyDate: row.values.study_date,
              }}
              createOrviewLink={"/report/create/" + row.values.study_id}
              viewLink={"/report/view/" + row.values.study_id}
              requestLink={"/report/request/" + row.values.study_id}
              addendun={"/report/addendun/" + row.values.study_id}
              reqAdvImagin={
                "/report/request-advance-imagin/" + row.values.study_id
              }
            />
          );
        },
      },
      {
        id: "delete",
        Header: "Delete",
        editable: false,
        Cell: ({ row }) => {
          return (
            <button
              type="button"
              name="delete"
              className="otjs-button otjs-button-red"
              onClick={() => setDelete(row.values.study_id)}
            >
              Unassigned
            </button>
          );
        },
      },
    ],
    [setDelete]
  );

  const data = useMemo(
    () =>
      reports.map((element, index) => {
        return { ...element, No: index + 1 };
      }),
    [reports]
  );
  return <CommonTable tableData={data} columns={columns} pagination={true} />;
}
